// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-pages-pesquisa-js": () => import("./../../../src/pages/pesquisa.js" /* webpackChunkName: "component---src-pages-pesquisa-js" */),
  "component---src-pages-series-js": () => import("./../../../src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-post-details-index-js": () => import("./../../../src/templates/PostDetails/index.js" /* webpackChunkName: "component---src-templates-post-details-index-js" */),
  "component---src-templates-posts-by-category-index-js": () => import("./../../../src/templates/PostsByCategory/index.js" /* webpackChunkName: "component---src-templates-posts-by-category-index-js" */),
  "component---src-templates-posts-by-serie-index-js": () => import("./../../../src/templates/PostsBySerie/index.js" /* webpackChunkName: "component---src-templates-posts-by-serie-index-js" */),
  "component---src-templates-posts-by-tag-index-js": () => import("./../../../src/templates/PostsByTag/index.js" /* webpackChunkName: "component---src-templates-posts-by-tag-index-js" */),
  "component---src-templates-posts-listing-index-js": () => import("./../../../src/templates/PostsListing/index.js" /* webpackChunkName: "component---src-templates-posts-listing-index-js" */)
}

